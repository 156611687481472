<template>
    <div>
        <div class="d-flex">
          <h2 v-if="ifEdit" @click="$router.push(`/roles`)">Edit Role</h2>
          <h2 v-else @click="$router.push(`/roles`)">Create Role</h2>
          <div v-if="!isLoading" class="form-group ml-1 d-flex align-items-center">
                <b-form-checkbox v-model="status" :checked="status" class="my-checkbox ml-1" name="check-button" switch>
                  <span class="switch-icon-left">
                    Active
                  </span>
                  <span class="switch-icon-right">
                    Inactive
                  </span>
                </b-form-checkbox>
              </div>
        </div>
          <div v-if="isLoading" style="display: flex;justify-content: center;height: 400px;align-items: center;">
          <b-spinner style="width: 3rem; height: 3rem;" variant="secondary"
            />
          </div>
          <div v-else>
           <div class="card px-3 py-2 mt-1">
          <div class="form-row justify-content-center">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Role Name{{ app }}</label>
              <input
                type="text"
                v-model="roleName"
                class="form-control"
                id="superAdmin"
                placeholder="Super Admin"
              />
            </div>
              <label class="col-md-12 font-weight-bolder h5">Applications</label>
              <div class="col-md-12 d-flex justify-content-around">
              <div class="manualRadio form-group" v-for="item in applications" @click="HandelApplication(item.id)" :key="item.id" :class="selectedApplications[item.id] ? 'manualradioborder' : ''">
               <div :class="selectedApplications[item.id] ? 'applicationClicked' : 'applications'">
                </div>
                <div class="pl-1">
                  {{ item.name_en }}
                </div>
                </div>
              </div>
            <div class="form-group col-md-12" >
              <label for="inputEmail4" class="font-weight-bolder h5">Permission Type </label>
              <div class="d-flex justify-content-around">
              <div class="manualRadio" @click="handelpermisssions('allowall')" :class="permissionType=='allowall' ? 'manualradioborder' : ''">
                <b-form-radio
                  name="payment-method"
                  v-model="permissionType"
                  value="allowall"
                >
                  Allow All
                </b-form-radio>
              </div>
              <div class="manualRadio" @click="handelpermisssions('denyall')" :class="permissionType=='denyall' ? 'manualradioborder' : ''">
                <b-form-radio
                  name="payment-method"
                  v-model="permissionType"
                  value="denyall"
                >
                  Deny All 
                </b-form-radio>
              </div>
              <div class="manualRadio"  @click="handelpermisssions('customrights')" :class="permissionType=='customrights' ? 'manualradioborder' : ''">
                <b-form-radio
                  name="payment-method"
                  v-model="permissionType"
                  value="customrights"
                >
                  Custom Rights
                </b-form-radio>
              </div>
            </div>
          </div>
          </div>
          </div>
           <div class="card p-3">
          <div class="form-row justify-content-center">
            <div class="col-md-7">
            <div class="d-flex align-items-center" style="border-radius:13px; background-color: rgb(152 182 226 / 21%); width: 100%" >
              <feather-icon icon="SearchIcon" class="searchIcon" size="21" />
              <vs-input color="red" v-model="searchQuery" @input="performSearch" shadow type="text" class="searchInput" placeholder="Search"> </vs-input>
            </div>
            </div>
              <div v-for="item in permissions" :key="item.id" v-if="checkIsApplicationParent(item.childData)  && show" class="col-md-5 mx-2 p-2 mt-1" style="border-radius:13px; background-color: rgb(152 182 226 / 21%); width: 100%">
              <div class="d-flex justify-content-between">
                <div  class="text-black" style="font-weight:700;color: #3f8cff;" :style="(searchQuery ? item.name.toLowerCase().replace(/\s/g,'').includes(searchQuery.toLowerCase().replace(/\s/g,'')) : false) ? 'background-color:#ffef0075': ''">
                {{item.name}}
               </div>   
               <b-form-checkbox
                  v-model="item[item.name.replace(/[^A-Za-z]+/g, '').trim()]"
                  :checked="item[item.name.replace(/[^A-Za-z]+/g, '').trim()]"
                  class="custom-control-primary pl-1"
                  name="check-button"
                  @change="handelClickClear(item[item.name.replace(/[^A-Za-z]+/g, '').trim()],item.childData,item.name.replace(/[^A-Za-z]+/g, '').trim())"
                  switch
                >
                </b-form-checkbox>
                </div>
                  <crud-component 
                  v-for="children in item.childData" :key="children.id"
                  v-if="checkIsApplicationChild(children)" 
                  :item="children.id" 
                  @customRights="customRights" 
                  :bold="searchQuery ? children.name.toLowerCase().replace(/\s/g,'').includes(searchQuery.toLowerCase().replace(/\s/g,'')) : false" 
                  :value="permissionsData[children.id]" 
                  :crud="item.name.replace(/[^A-Za-z]+/g, '').trim()=='DashboardView' ? 'switch' :'checkboxCrud'" 
                  :title="children.name"/>
            </div>  
          <div class="col-md-7 p-2 mt-1"> 
            <button
              type="button"
              class="btn btn-primary mr-1"
              style="border: 1px solid black"
                @click="createRoleHandelClick"
            >
              Save
            </button>
            <button type="button" class="btn btn-default" @click="$router.push('/roles')">Cancel</button>
          </div>
          </div>
          </div>
        </div>
          </div>
</template>
<script>
import draggable from "vuedraggable";
import axios from "axios";
import CrudComponent from "./CrudComponent";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from 'vue-select'
export default {
  components: {
    draggable,
    CrudComponent,
    vSelect
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      data: {},
      permissionType:"",
        roleName:"",
      rolesData:[],
      permissions:[],
      permissionsTest:[],
      applications:[],
      permissionsData:{},
      selectedApplications:{},
      popoverShow: false,
      popoverShow1: false,
      value: "1",
      searchQuery: '',
      searching: false,
      searchResults: [],
      searchComplete: false,
      ifEdit:false,
      isLoading:true,
      show:true,
      status:false,
    };
  },
  created() {},
  async mounted(){
    // setTimeout(() => {
    //   let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Role and Permission')
    //   if(is_valid.length < 1){this.$router.push('/')}
    // }, 1000);
    await this.getRoles();
    this.getApplications();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
    this.isLoading = this.ifEdit
  },
  computed:{
    app(){
    // setTimeout(() => {
    //   let is_valid = this.$store.state.sidebar.filter(data => data.name == 'Role and Permission')
    //   if(is_valid.length < 1){this.$router.push('/')}
    // }, 1000);
  }
  },
  methods: {
    getEditValue(){
      this.show =false
      this.$http.post(`${this.baseURL}/role/view/${this.$route.params.id}`,
              {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials:true  
            }).then((res)=>{
              this.editItem = res.data.data
              this.id = this.editItem.id
              this.status = this.editItem.is_active ? true : false
              this.roleName = this.editItem.name
              this.selectedApplications = typeof JSON.parse(this.editItem.application_id) == 'object' ? JSON.parse(this.editItem.application_id) : {}
              let selectedTrue = false
              let selectedFalse = false
              if(this.editItem.permissions.length>0){ 
                this.editItem.permissions.map(item => {
                    this.permissionsData[item.permissionlist_id.id] = this.permissionsData[item.permissionlist_id.id] ?? {}
                    this.permissionsData[item.permissionlist_id.id].permission_id  = item.permissionlist_id.id ;
                    this.permissionsData[item.permissionlist_id.id].create = item.create ? true : false;
                    this.permissionsData[item.permissionlist_id.id].delete = item.delete? true : false;
                    this.permissionsData[item.permissionlist_id.id].read = item.read? true : false;
                    this.permissionsData[item.permissionlist_id.id].update = item.update? true : false
                    this.applications.map(i => {
                    if(this.selectedApplications[i.id]){
                      if(item.permissionlist_id[`app${i.id}`]){
                        if(item.create && item.read && item.update && item.delete){
                          selectedTrue=true
                        }else{
                          console.log(item.permissionlist_id.name)
                          selectedFalse=true
                        }
                        }
                    }
                    })
              })
              this.permissions.map((item)=>{
                item[item.name.replace(/[^A-Za-z]+/g, '').trim()] =false
                let childArr = item.childData.filter((inner)=> 
                 { 
                  if(this.checkIsApplicationChild(inner)){
                    return this.permissionsData[inner.id].create || this.permissionsData[inner.id].read || this.permissionsData[inner.id].update || this.permissionsData[inner.id].delete
                  }
                    return true
                  }
                )
                if(childArr.length == item.childData.length){
                  item[item.name.replace(/[^A-Za-z]+/g, '').trim()] =true
                }
              })
              this.show =true
              this.isLoading =false
            if(selectedTrue && !selectedFalse){
              this.permissionType = 'allowall'
            }else if(!selectedTrue && selectedFalse){
              this.permissionType = 'denyall'
            }else if(selectedTrue && selectedFalse){
              this.permissionType = 'customrights'
            }
              this.$forceUpdate();
            }
            if(selectedTrue && !selectedFalse){
              this.permissionType = 'allowall'
            }else if(!selectedTrue && selectedFalse){
              this.permissionType = 'denyall'
            }else if(selectedTrue && selectedFalse){
              this.permissionType = 'customrights'
            }
              this.show =true
              this.isLoading =false
            })
    },
    checkIsApplicationChild(item){
      if(item.app1 == 1 && this.selectedApplications[1]){
        return true
      }
      else if(item.app2 == 1 && this.selectedApplications[2]){
        return true
      }
      else if(item.app3 == 1 && this.selectedApplications[3]){
        return true
      }
      else if(item.app4 == 1 && this.selectedApplications[4]){
        return true
      }
      else if(item.app5 == 1 && this.selectedApplications[5]){
        return true
      }
      else if(item.app6 == 1 && this.selectedApplications[6]){
        return true
      }
      else if(item.app7 == 1 && this.selectedApplications[7]){
        return true
      }
      else if(item.app8 == 1 && this.selectedApplications[8]){
        return true
      }
      else if(item.app9 == 1 && this.selectedApplications[9]){
        return true
      } 
      else if(item.app10 == 1 && this.selectedApplications[10]){
        return true
      }
      else if(item.app_comman == 1){
        return true
      }else{
        return false
      }
    },
    checkIsApplicationParent(child){
      let array =  child.filter(item => this.checkIsApplicationChild(item));
      if(array.length>0){
        return true
      }else{
        return false
      }
    },
    customRights(id){
      this.permissionType = "customrights"
    },
    performSearch: _.debounce(function (search) {
      if(this.permissionsTest.length > 0){
      this.permissions = this.permissionsTest
      }
      if (this.searchQuery.trim() === '') {
        this.searchResults = []
        this.searchComplete = false;
        this.permissions = [...this.permissionsAll]
        return;
      }
      this.searching = true;
      this.searchComplete = false;
      this.searchResults = [];
        const query = this.searchQuery.toLowerCase().replace(/\s/g,'');
        this.searchResults = this.permissions.filter(item => item.name.toLowerCase().replace(/\s/g,'').includes(query));
        this.permissions.map((item) => {
          let array =  item.childData.filter(item1 => item1.name.toLowerCase().replace(/\s/g,'').includes(query));
          let check = this.searchResults.filter(item1 => item1.id == item.id)
          if(array.length>0 && check.length<=0){
            this.searchResults.push(item)
          }
        })
         this.permissionsTest = this.permissions
         this.permissions = this.searchResults
        this.searchComplete = true;
        this.$forceUpdate();
    },1000),
    HandelApplication(id){
      this.selectedApplications[id] = this.selectedApplications[id] == undefined ? false : this.selectedApplications[id]
      this.selectedApplications[id] = this.selectedApplications[id] == true ? false : true
      this.permissionsData = {}
      this.permissions = []
      this.permissions = this.permissionsAll
      this.permissions.map((item) => {
              item[item.name.replace(/[^A-Za-z]+/g, '').trim()] = false
              let sindleSwitchArray = [1,2,3]
              item.childData.map((children) => {
                if(sindleSwitchArray.includes(children.id)){
                  this.permissionsData[children.id] = {permission_id: children.id,read:false}
                }else{
                  this.permissionsData[children.id] = {permission_id: children.id,create:false,read:false,update:false,delete:false}
                }
                  })
            })
      this.permissionType = "denyall"
      this.$forceUpdate()
    },
    handelpermisssions(value){
      this.permissionType = value
      if(value == 'allowall'){
        this.permissionsAll.map((item) => {
          item[item.name.replace(/[^A-Za-z]+/g, '').trim()] = true
        })
        var result = Object.keys(this.permissionsData).map((key) => this.permissionsData[key]);
        this.permissionsData = {}
        // let sindleSwitchArray = [1,2,0]
        let sindleSwitchArray = []
         result.map((item,index) => {
          let test = null
          this.permissionsAll.map(data => {
           data.childData.map(child =>{
            if(child.id == item.permission_id){
              test = child
            }
           });
          })
          if(test && item.permission_id && this.checkIsApplicationChild(test)){
            if(sindleSwitchArray.includes(index)){
              this.permissionsData[item.permission_id] = {permission_id: item.permission_id,read:true}
            }else{
              this.permissionsData[item.permission_id] = {permission_id: item.permission_id,create:true,read:true,update:true,delete:true} 
            }
          }
          else{
            if(sindleSwitchArray.includes(index)){
              this.permissionsData[item.permission_id] = {permission_id: item.permission_id,read:item.read}
            }else{
              this.permissionsData[item.permission_id] = {permission_id: item.permission_id,create:item.create,read:item.read,update:item.update,delete:item.delete} 
            }
          }
        })
      }
      else if(value == 'denyall'){
        this.permissionsAll.map((item) => {
          item[item.name.replace(/[^A-Za-z]+/g, '').trim()] = false
        })
        var result = Object.keys(this.permissionsData).map((key) => this.permissionsData[key]);
        this.permissionsData = {}
        let sindleSwitchArray = [1,2,0]
        result.map((item,index) => {
          console.log(result)
          let test
          this.permissionsAll.map(data => {
           data.childData.map(child =>{
            if(child.id == item.permission_id){
            test = child
            }
           });
          })
          if(test && item.permission_id && this.checkIsApplicationChild(test)){
            if(sindleSwitchArray.includes(index)){
              this.permissionsData[item.permission_id] = {permission_id: item.permission_id,read:false}
            }else{
            this.permissionsData[item.permission_id] = {permission_id: item.permission_id,create:false,read:false,update:false,delete:false} 
          }
         } else{
            if(sindleSwitchArray.includes(index)){
              this.permissionsData[item.permission_id] = {permission_id: item.permission_id,read:item.read}
            }else{
              this.permissionsData[item.permission_id] = {permission_id: item.permission_id,create:item.create,read:item.read,update:item.update,delete:item.delete} 
            }
          }
      })
      }
      this.$forceUpdate()
    },
          handelClickClear(value,items,name){
        this.permissionType = "customrights"
          if(value !== true){
            items.map((data) => {
              if(this.checkIsApplicationChild(data)){
                if(name == 'DashboardView'){
                this.permissionsData[data.id] = {permission_id: data.id,read:false}
              }else{
                this.permissionsData[data.id] = {permission_id: data.id,create:false,read:false,update:false,delete:false}
              }
              }
            })
            this.$forceUpdate()
          }else{
            items.map((data) => {
              if(this.checkIsApplicationChild(data)){
              if(name == 'DashboardView'){
                this.permissionsData[data.id] = {permission_id: data.id,read:true}
              }else{
                this.permissionsData[data.id] = {permission_id: data.id,create:true,read:true,update:true,delete:true}
              }
              }
            })
            this.$forceUpdate()
          }
        },
        createRoleHandelClick(){
        let array = []
        Object.keys(this.selectedApplications).map((key) => {
          if(this.selectedApplications[key] == true){
            array.push(key)
          }
        })
          var result = Object.keys(this.permissionsData).map((key) => this.permissionsData[key]);
          result.map((item) => {
            item.read = item.read == true ? 1 : 0
            item.create = item.create == true ? 1 : 0
            item.update = item.update == true ? 1 : 0
            item.delete = item.delete == true ? 1 : 0
          })
          let AllData = {
                name: this.roleName,
                is_active: this.status ? 1 : 0,
                application_id: this.selectedApplications,
                permissions: result,
          }
          if(this.ifEdit){
            AllData={id:this.id,...AllData}
          }
              this.$http.post(`${this.baseURL}/role/${this.ifEdit ? 'update' : 'create'}`,AllData,
              {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials:true
            }).then((json)=>{
              if(json.data.status == 201){
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then(() => {
                this.$router.push(`/roles`)
              })
              }else{
                this.$swal({
                title: "Error!!",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Error",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              }
            })
        // axios.defaults.headers.common['Content-Length'] = '1';
        // axios({
        //       method: "POST",
        //       url: `${this.baseURL}/role/create`,
        //       headers: {  'Authorization': 'Jwt eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmaXJzdE5hbWUiOiJIaXRla3NoYSIsImZ1bGxOYW1lIjoiSGl0ZWtzaGEgU2hldGgiLCJyb2xlIjoiU3VwZXIgQWRtaW4iLCJyb2xlTmFtZSI6IlN1cGVyIEFkbWluIiwiYWJpbGl0eSI6W3siYWN0aW9uIjoibWFuYWdlIiwic3ViamVjdCI6ImFsbCJ9XSwidXNlcl9pZCI6MSwiaXNzIjoiYXBpLnN0YWcyLmljbXNhcHBzLmNvbSIsImF1ZCI6WyJhcGkuc3RhZzIuaWNtc2FwcHMuY29tIiwic3RhZzIuaWNtc2FwcHMuY29tIl0sImV4cCI6MTY4NTUyNTQ5NX0.FPInP9x7cFY3miGTJSEzrv091uIFEiAmCNc8LM1v0Ps', 
        //         'Content-Type': 'application/json'},
        //       data:AllData,
        //       withCredentials:true,
        //       maxBodyLength: Infinity,
        //       }).then((res)=>{
        //     })
            
        },
    HandelClickNewRole(){
    },
    permissionHandelchange(value){
     this.permissionType = value
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
      getApplications(){
        this.$http.post(`${this.baseURL}/application/index`,).then((res)=>{
              this.applications = res.data.data
            })
    },
    async getRoles(){
      this.permissionsData={}
      await this.$http.post(`${this.baseURL}/permissiongroup/index`).then((res)=>{
            this.permissions = res.data.data
            this.permissionsAll = res.data.data
              this.permissions.map((item) => {
              item[item.name.replace(/[^A-Za-z]+/g, '').trim()] = false
              let sindleSwitchArray = [1,2,3]
              item.childData.map((children) => {
                if(sindleSwitchArray.includes(children.id)){
                  this.permissionsData[children.id] = {permission_id: children.id,read:false}
                }else{
                  this.permissionsData[children.id] = {permission_id: children.id,create:false,read:false,update:false,delete:false}
                }
                  })
            })
              this.$forceUpdate()
          })
    }
  },
};
</script>
<style>
.vs-input__label{
  margin-bottom: 0px !important;
}
.applications{
  width: 20px;
    height: 20px;
    border-radius: 15px;
    border: 1px solid #b5b5b5;
}
.applicationClicked{
    width: 20px;
     height: 20px;
    border-radius: 15px;
    border: 1px solid #3f8cff;
    background-color: #3f8cff;
}
.customSearch{    
    background-color: #e9f0f9;
    margin: 4px 2px;
    padding: 10px 39px;
    border-radius: 15px;
}
.searchIcon{
  width: 10%
}
.searchInput{
  width: 90%
}
@media only screen and (max-width: 576px){
.searchIcon{
  width: 15%
}
.searchInput{
  width: 85%
}
}
.manualRadio{
  border: 2px solid #cfd7df;
    width: 30%;
    height: 50px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.manualradioborder{

  border: 2px solid #007bff;
}
.custom-radio{
  border:none !important;
} 
</style>